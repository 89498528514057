import React, { useState, createContext } from "react";
import "./App.css";
import MyNavbar from "./MyNavbar";
import TerrainComponent from "./TerrainComponent";
import { FilterContext } from "./FilterContext";

function App() {
  const [locations, setLocations] = useState([]);
  const [classes, setClasses] = useState([]);
  return (
    <FilterContext.Provider
      value={{ locations, setLocations, classes, setClasses }}
    >
      <div className="App bp5-dark">
        <MyNavbar />
        <TerrainComponent />
      </div>
    </FilterContext.Provider>
  );
}

export default App;