import { createContext, useContext } from "react";

const FilterContext = createContext({
  locations: [],
  setLocations: () => {},
  classes: [],
  setClasses: () => {},
});

const useFilters = () => useContext(FilterContext);

export { FilterContext, useFilters };
