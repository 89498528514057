import {
  Alignment,
  Button,
  Menu,
  MenuItem,
  Navbar,
  Popover,
  Position,
  Icon,
  IconSize,
} from "@blueprintjs/core";
import React from "react";
import { useFilters } from "./FilterContext";
import MyMultiSelect from "./MultiSelect";
import { classData, locationData } from "./static";

const userMenu = (
  <Menu>
    <MenuItem text="Profile" icon="user" />
    <MenuItem text="Settings" icon="cog" />
    <MenuItem text="Logout" icon="log-out" />
  </Menu>
);

const MyNavbar = () => {
  const { locations, setLocations, classes, setClasses } = useFilters();
  return (
    <Navbar>
      <Navbar.Group align={Alignment.LEFT}>
        <Navbar.Heading>
          Manifest
          <Icon icon="search-around" className="slow-spin" size={10} />
        </Navbar.Heading>
        <Navbar.Divider />
        <Button
          className="bp3-minimal"
          icon="geosearch"
          text="Location"
          disabled
        />
        <MyMultiSelect
          items={locationData}
          selectedItems={locations}
          setSelectedItems={setLocations}
        />
        {/*         <Button
          className="bp3-minimal"
          icon="barcode"
          text="Supply Class"
          disabled
        />
        <MyMultiSelect
          items={classData}
          selectedItems={classes}
          setSelectedItems={setClasses}
        /> */}
      </Navbar.Group>
      <Navbar.Group align={Alignment.RIGHT}>
        <Popover content={userMenu} position={Position.BOTTOM_RIGHT}>
          <Button className="bp3-minimal" icon="user" text={"Jon Garrity"} />
        </Popover>
      </Navbar.Group>
    </Navbar>
  );
};

export default MyNavbar;
