import React, { useState } from "react";
import { MultiSelect } from "@blueprintjs/select";
import { MenuItem, Button, Icon } from "@blueprintjs/core";

const MyMultiSelect = ({ items = [], selectedItems, setSelectedItems }) => {
  const removeItem = (mostUpToDateSelectedItems = [], item = "") =>
    mostUpToDateSelectedItems.filter((valueInArray) => valueInArray !== item);

  const handleItemSelect = (item) => {
    console.log("select");
    setSelectedItems((mostUpToDateSelectedItems) => {
      if (!mostUpToDateSelectedItems.includes(item))
        return [...mostUpToDateSelectedItems, item];
      return removeItem(mostUpToDateSelectedItems, item);
    });
  };

  const handleTagRemove = (item) => {
    console.log("removing");
    setSelectedItems((mostUpToDateSelectedItems) =>
      removeItem(mostUpToDateSelectedItems, item)
    );
  };

  const handleClear = () => {
    setSelectedItems([]); // Clear all selected items
  };

  const renderTag = (item) => item.label;

  return (
    <MultiSelect
      items={items}
      selectedItems={selectedItems}
      onItemSelect={handleItemSelect}
      tagRenderer={renderTag}
      onRemove={handleTagRemove}
      itemRenderer={(item, { handleClick, modifiers }) => (
        <MenuItem
          key={item.label}
          onClick={handleClick}
          text={item.label}
          active={modifiers.active}
        />
      )}
      tagInputProps={{
        placeholder: "Select...",
        onRemove: handleTagRemove,
        rightElement: (
          <Button minimal small icon="cross" onClick={handleClear} />
        ),
      }}
    />
  );
};

export default MyMultiSelect;
