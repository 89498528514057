import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import React, { useEffect, useRef, useState } from "react";
import { Drawer } from "@blueprintjs/core";
import "./App.css";
import { useFilters } from "./FilterContext";

mapboxgl.accessToken =
  "pk.eyJ1IjoianRnYXJyaXR5IiwiYSI6ImNqcGVrbHF3ejAxODQza2w2amxzbjExY3oifQ.ISLuTlbqTMcEb28WKB6zow"; // Replace with your access token

const TerrainComponent = () => {
  const map = useRef(null);
  const mapContainer = useRef(null);
  const { locations } = useFilters();
  const [selectedLocation, setSelectedLocation] = useState();

  useEffect(() => {
    if (map.current) return; // Ensures the map is initialized only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/satellite-streets-v11", // style URL
      projection: "globe",
      center: [-117.35715323162566, 33.291308548997854], // starting position [lng, lat]
      zoom: 3, // starting zoom
      maxZoom: 16,
    });

    map.current.on("load", () => {
      map.current.setFog({
        color: "rgba(0, 0, 0, 0.5)", // Example: semi-transparent black fog
        "horizon-blend": 0,
        // Add other fog properties as needed
      });

      map.current.addSource("mapbox-dem", {
        type: "raster-dem",
        url: "mapbox://mapbox.mapbox-terrain-dem-v1",
        tileSize: 512,
        maxzoom: 14,
      });

      map.current.setTerrain({ source: "mapbox-dem", exaggeration: 1.5 });
    });
  }, []);

  useEffect(() => {
    if (!map.current) return; // Ensures this runs only when the map is initialized

    const markers = [];
    locations.forEach(({ value: location }) => {
      if (
        location &&
        location.lng !== undefined &&
        location.lat !== undefined
      ) {
        // Create a div element for the marker
        const el = document.createElement("div");
        el.className = "pulse";

        // Add the custom marker to the map
        const marker = new mapboxgl.Marker(el)
          .setLngLat([location.lng, location.lat])
          .addTo(map.current);
        marker.getElement().addEventListener("click", () => {
          setSelectedLocation(location);
        });
        markers.push(marker);
      }
    });

    // Draw lines between every pair of markers
    markers.forEach((marker, index) => {
      for (let i = index + 1; i < markers.length; i++) {
        const lineCoordinates = [
          marker.getLngLat().toArray(),
          markers[i].getLngLat().toArray(),
        ];

        const lineId = `line-${index}-${i}`;
        const line = {
          type: "Feature",
          geometry: {
            type: "LineString",
            coordinates: lineCoordinates,
          },
        };

        // Add a source and a layer for each line
        if (!map.current.getSource(lineId)) {
          map.current.addSource(lineId, {
            type: "geojson",
            data: line,
          });

          map.current.addLayer({
            id: lineId,
            type: "line",
            source: lineId,
            layout: {
              "line-cap": "round",
              "line-join": "round",
            },
            paint: {
              "line-color": "#ff0000", // Line color
              "line-width": 1.5, // Line width
            },
          });
        }
      }
    });

    if (markers.length > 0) {
      // Initialize bounds
      const bounds = new mapboxgl.LngLatBounds();

      // Extend bounds to include each marker's position
      markers.forEach((marker) => {
        bounds.extend(marker.getLngLat());
      });

      // Adjust map view to fit all the markers within the bounds
      map.current.fitBounds(bounds, { padding: 120 });
    }

    return () => {
      markers.forEach((marker) => marker.remove()); // Cleanup markers
      markers.forEach((_, index) => {
        for (let i = index + 1; i < markers.length; i++) {
          const lineId = `line-${index}-${i}`;
          if (map.current.getLayer(lineId)) {
            map.current.removeLayer(lineId);
            map.current.removeSource(lineId);
          }
        }
      });
    };
  }, [locations]);
  // Dependency on 'locations'

  console.log("selectedLocation?.url :>> ", selectedLocation?.url);
  console.log(selectedLocation?.lat);
  return (
    <div>
      <Drawer
        isOpen={!!selectedLocation}
        onClose={() => {
          setSelectedLocation();
        }}
      >
        <iframe
          src={selectedLocation?.url}
          title="Location"
          allow="*"
          width="100%"
          height="100%"
        />
      </Drawer>
      <div ref={mapContainer} style={{ height: "100vh" }} />
    </div>
  );
};

export default TerrainComponent;
