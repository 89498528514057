const locationData = [
  { label: "Brisbane, AUS", value: { lat: -27.479267, lng: 153.043935 } },
  {
    label: "Camp Pendleton, CA",
    value: { lat: 33.291308548997854, lng: -117.35715323162566 },
  },
  {
    label: "Cebu, PHL",
    value: {
      lat: 10.427201,
      lng: 123.936909,
      url: "https://app.tagup.io",
    },
  },
  { label: "Darwin, AUS", value: { lat: -12.41703, lng: 130.87982 } },
  { label: "Guam, GUM", value: { lat: 13.446529, lng: 144.759431 } },
  { label: "Manila, PHL", value: { lat: 14.599462, lng: 120.976618 } },
  {
    label: "MCBH Kaneohe Bay, HI",
    value: { lat: 21.444684, lng: -157.752068 },
  },
  { label: "Okinawa, JPN", value: { lat: 26.809706, lng: 128.20446 } },
  { label: "Peleliu, PLW", value: { lat: 7.002168, lng: 134.243738 } },
  { label: "Seoul, KOR", value: { lat: 37.608021, lng: 126.993545 } },
  { label: "Singapore, SIN", value: { lat: 1.140453, lng: 103.24131 } },
  { label: "Tinian, MNP", value: { lat: 15.005591, lng: 145.635928 } },
  { label: "Travis AFB, CA", value: { lat: 38.339499, lng: -121.951666 } },
];

export { locationData };

const classData = [
  { label: "Class I", value: { lat: -27.479267, lng: 153.043935 } },
  {
    label: "Class II",
    value: { lat: 33.291308548997854, lng: -117.35715323162566 },
  },
  { label: "Class III", value: { lat: 10.427201, lng: 123.936909 } },
  { label: "Class IV", value: { lat: -12.41703, lng: 130.87982 } },
  { label: "Class V", value: { lat: 13.446529, lng: 144.759431 } },
  { label: "Class VI", value: { lat: 14.599462, lng: 120.976618 } },
  {
    label: "Class VII",
    value: { lat: 21.444684, lng: -157.752068 },
  },
  { label: "Class VIII", value: { lat: 26.809706, lng: 128.20446 } },
  { label: "Class IX", value: { lat: 7.002168, lng: 134.243738 } },
  { label: "Class X", value: { lat: 37.608021, lng: 126.993545 } },
];

export { classData };
